define('ember-tooltips/mixins/components/tooltips', ['exports', 'ember', 'ember-tooltips/utils/render-tooltip'], function (exports, _ember, _emberTooltipsUtilsRenderTooltip) {
  'use strict';

  var on = _ember['default'].on;

  exports['default'] = _ember['default'].Mixin.create({

    /**
    The tooltip object
     @property tooltip
    @type Object
    */

    tooltip: null,

    /**
    A list of Tooltip properties currently supported
    by this addon
     @property tooltipSupportedProperties
    @type Array
    */

    tooltipSupportedProperties: ['auto', 'content', 'duration', 'effectClass', 'event', 'hideOn', 'place', 'showOn', 'spacing', 'tabIndex', 'typeClass', 'visibility'],

    /* Tooltip options - see http://darsa.in/tooltip/ */

    tooltipAuto: true,
    tooltipContent: null,
    tooltipDuration: null,
    tooltipEffectClass: 'slide', // fade, grow, slide, null
    tooltipEvent: 'hover', // hover, click, focus, ready, or none
    tooltipHideOn: null,
    tooltipPlace: 'top',
    tooltipShowOn: null,
    tooltipSpacing: 10,
    tooltipTabIndex: 0, // A positive integer (to enable) or -1 (to disable)
    tooltipTypeClass: null,
    tooltipVisibility: null, // for manual-mode triggering

    /**
    Removes a tooltip from the DOM if the element it is attached
    to is destroyed.
     @method destroyTooltip
    */

    destroyTooltip: on('willDestroyElement', function () {
      var tooltip = this.get('tooltip');

      if (tooltip) {
        tooltip.effect(null); // Remove animation
        tooltip.detach(); // Remove the tooltip from the document
        this.$().off(); // Remove all event listeners
      }

      /* Remove observer, even if it was never added */

      this.removeObserver('tooltipVisibility', this, this._tooltipVisibilityDidChange);
      this.removeObserver('tooltipContent', this, this._tooltipContentDidChange);
    }),

    /**
    Adds a tooltip to the current component using the values of the tooltip
    properties on this component's helper or, if a `{{tooltip-on-parent}}` component is
    passed, the values set on that component.
     In the latter case, the `{{tooltip-on-parent}}` block template will
    be used for the tooltip content.
     ## Example 1 - Setting values on a component
     ```hbs
    {{#some-component tooltipContent='This will show' tooltipPlace='right'}}
      Hover over me
    {{/some-component}}
    ```
     ## Example 2 - Using a child component
     ```hbs
    {{#some-component}}
       {{#tooltip-on-parent place='right'}}
        This will show
      {{/tooltip-on-parent}}
       Hover over me
     {{/some-component}}
    ```
     Usually you won't have to call the `renderTooltip` method directly but it's still
    made publically available. In the above examples, the components handle calling
    this `renderTooltip()` method at the correct time.
     Supported options are in the format tooltip{$capitalizedProperty} where
    capitalizedProperty is each property, capitalized, in tooltipSupportedProperties.
     @method renderTooltip
    @param [maybeTooltipComponent] An optionally-passed component for a `{{tooltip-on-parent}}` class
    */

    renderTooltip: on('didInsertElement', function (maybeTooltipComponent) {
      var componentWasPassed = _ember['default'].typeOf(maybeTooltipComponent) === 'instance';
      var component = componentWasPassed ? maybeTooltipComponent : _ember['default'].Object.create({});
      var renderContext = componentWasPassed ? component : this;

      var content = this.get('tooltipContent');
      var tooltip = undefined,
          tooltipOptions = undefined;

      var keys = Object.keys(this);
      var hasTooltipContentProperty = _ember['default'].$.inArray('tooltipContent', keys) !== -1;

      if (componentWasPassed) {
        var componentContent = component.get('content');

        if (componentContent) {
          content = componentContent;
        } else {
          content = component.get('element').innerHTML;
        }
      }

      if (!content && !hasTooltipContentProperty) {
        return;
      }

      tooltipOptions = {
        content: content
      };

      this.get('tooltipSupportedProperties').forEach(function (property) {

        /* Ignore content because we dealt with it already */

        if (property === 'content') {
          return;
        }

        var capitalizedProperty = _ember['default'].String.capitalize(property);

        tooltipOptions[property] = component.get(property) || this.get('tooltip' + capitalizedProperty);
      }, this);

      tooltip = (0, _emberTooltipsUtilsRenderTooltip['default'])(this.get('element'), tooltipOptions, renderContext);

      this.set('tooltip', tooltip);

      /* Bind observer if tooltipContent changes */

      this.addObserver('tooltipContent', this, this._tooltipContentDidChange);

      /* Bind observer if in manual-triggering mode */

      if (tooltipOptions.event === 'manual' || tooltipOptions.event === 'none') {
        if (componentWasPassed) {

          /* Keep track of child tooltip component */

          this.set('tooltipChildComponent', component);

          /* Turn 'tooltipVisibility' into a computed property, reading
          from child tooltip component's 'visibility' option */

          _ember['default'].defineProperty(this, 'tooltipVisibility', _ember['default'].computed.reads('tooltipChildComponent.visibility'));
        }

        this.addObserver('tooltipVisibility', this, this._tooltipVisibilityDidChange);
        this._tooltipVisibilityDidChange();
      }
    }),

    /**
    Updates the content value of the tooltip with value of 'tooltipContent'.
     @method _tooltipContentDidChange
    @private
    */

    _tooltipContentDidChange: function _tooltipContentDidChange() {
      var tooltip = this.get('tooltip');

      if (tooltip) {
        tooltip.content(this.get('tooltipContent'));
      }
    },

    /**
    Opens or closes tooltip based on value of 'tooltipVisibility'.
    Only used when event is 'manual'.
     @method _tooltipVisibilityDidChange
    @private
    */

    _tooltipVisibilityDidChange: function _tooltipVisibilityDidChange() {
      var tooltip = this.get('tooltip');

      if (this.get('tooltipVisibility')) {
        tooltip.show();
      } else {
        tooltip.hide();
      }
    },

    /**
    Call this method on any component to attach tooltips to all elements in its
    template that have a `.tooltip` class. Tooltip options are set using
    data attributes.
     ```hbs
    {{!--app/templates/components/some-widget.hbs--}}
    <div
      class="has-tooltip"
      data-tooltip-content="This will show"
      data-tooltip-position="right"
      data-tooltip-effect-class="grow"
    >
      Hover over me!
    </div>
    ```
     ```js
    // app/components/some-widget.js
     export default Ember.Component.extend({
       renderTooltipsInTemplate: function() {
        this.renderTooltip();
      }.on('didInsertElement'),
     });
    ```
     Supported options are in the format data-tooltip-{$dasherizedProperty} where
    dasherizedProperty is each property, dasherized, in tooltipSupportedProperties.
      @method renderChildTooltips
    */

    renderChildTooltips: function renderChildTooltips() {
      var _this = this;
      var tooltipSupportedProperties = this.get('tooltipSupportedProperties');

      _ember['default'].run.scheduleOnce('render', this, function () {
        var tooltipOptions = {};

        this.$().find('.has-tooltip').each(function () {
          var $element = _ember['default'].$(this);

          tooltipSupportedProperties.forEach(function (property) {
            var capitalizedProperty = _ember['default'].String.capitalize(property);
            var dasherizedProperty = _ember['default'].String.dasherize(property);
            var value = $element.data('tooltip-' + dasherizedProperty);

            tooltipOptions[property] = value || _this.get('tooltip' + capitalizedProperty);
          }, this);

          (0, _emberTooltipsUtilsRenderTooltip['default'])(this, tooltipOptions);
        });
      });
    }

  });
});