
function onViewPackages(){
	$("#materialsTabPanel").css("display", "none");
	$("#packagesTabPanel").css("display", "block");

	$("#materialsCaret").css("display", "none");
	$("#packagesCaret").css("display", "block");

	$("#materialsLink1").removeClass("active");
	$("#packagesLink1").addClass("active");
}

function onViewMaterials(){
	$("#materialsTabPanel").css("display", "block");
	$("#packagesTabPanel").css("display", "none");

	$("#materialsCaret").css("display", "block");
	$("#packagesCaret").css("display", "none");

	$("#packagesLink1").removeClass("active");
	$("#materialsLink1").addClass("active");
}



function onOpenLeftMenu(){

	$('.mobile-menu.col-md-3').animate({left:'0%'}, 500);
 	$('.right-menu').animate({left:'45%'}, 500);
 	$('.right-menu').addClass("m-right-menu");
	$('.mobile-menu-btn').animate({left:'47%'}, 500);
}

function onCloseLeftMenu(){
	$('.mobile-menu.col-md-3').animate({left:'-50%'}, 500);
  $('.m-right-menu').animate({left:'0%'}, 500);
	$('.mobile-menu-btn').animate({left:'10px'}, 500);
}
