function onRoomSelectionClicked(){

//  $('#materials_panel').animateCSS('fadeIn');
/*
  $('#overlayImagesGroup').animateCSS('fadeOutUp', function() {
    console.log('Boom! First animation Complete');

    $(this).animateCSS("fadeInUp", function(){
      console.log('Boom Boom! Second animation Complete');
    })

  });
*/
}
